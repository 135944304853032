<template>
   <v-form ref="voucher-setting-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Voucher Setting</h3>
         </v-col>
         <v-col cols="12">
            <v-btn color="secondary" class="m-auto px-8" large @click.prevent="$router.go(-1)" :disabled="isFetching">Back</v-btn>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="title" label="Service title" :required="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col cols="12">
            <v-btn color="primary" class="px-8 form-btn" large :disabled="isFetching" type="submit">Save</v-btn>
            <FormDeleteBtn :removeAction="removeItem" v-if="id != undefined" />
         </v-col>
      </v-row>
   </v-form>
</template>

<script>
import dummyData from "@/components/dummy-data"
import FormControl from "@/components/form/FormControl";
import FormDeleteBtn from "@/components/form/FormDeleteBtn";
import { mapActions, mapState } from "vuex";

export default {
   name: "VoucherTypeDetail",
   components: {
      FormControl,
      FormDeleteBtn,
   },
   data() {
      return {
         title: '',
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
      id: function() {
         return this.$route.params.id;
      }
   },
   methods: {
      ...mapActions(["sendRequest"], "request"),
      ...mapActions(["setDialogMessage", "setShowDialog"]),

      async initData() {
         if(this.id) {
            const resp = await this.sendRequest({url: '', method: 'GET', params: {id: this.id}});
            if(resp.data) {
               const searchData = dummyData.voucher_setting_list.filter(x => x.id == this.id);
               if(searchData.length) {
                  const data = searchData[0];
                  this.title = data.title;
               }
            }
         }
      },
      async formSubmit() {
         console.log('save');
         const isValid = this.$refs['voucher-setting-form'].validate();
         if(isValid) {
            const formdata = {
               id: this.id,
               //...
            };
            const resp = await this.sendRequest({
               url: '', 
               method: 'POST', 
               data: formdata,
            });
            console.log('resp', resp);
            if(resp) {
               this.setDialogMessage({ 
                  message: resp.data ? "Successful" : "Failed", 
                  isError: !resp.data, 
                  returnLink: (this.id === undefined ? { name: 'voucherType' } : null )
               });
               this.setShowDialog(true);
            }
         }
      },
      
      async removeItem(dialog) {
         console.log('delete');
         dialog.value = false;
         const resp = await this.sendRequest({ url: '', method: 'DELETE', params: {id: this.id} });
         if(resp.data) {
            this.$router.push({name: 'voucherType'});
         } else {
            this.setDialogMessage({ 
               message: "Failed", 
               isError: true,
            });
            this.setShowDialog(true);
         }
      },
   },
   async mounted() {
      await this.initData();
   }
}
</script>
